import PaymentType from './PaymentType.vue'
import PaymentTypeCreate from './Create.vue'
import PaymentTypeEdit from './Create.vue'

export default [
    {
        path: '/payment_type',
        name: 'PaymentType',
        component: PaymentType
    },
    {
        path: '/payment_type/create',
        name: 'PaymentTypeCreate',
        component: PaymentTypeCreate
    },
    {
        path: '/payment_type/edit/:id',
        name: 'PaymentTypeEdit',
        component: PaymentTypeEdit
    },
]
